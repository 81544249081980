import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  TokenBody,
  TransactionBody,
  User,
  UserBody,
  Token,
  FollowersBody,
  Transaction,
  CommentsBody,
  imagescommentBody,
  Comments,
  MaketCap,
  MaketCapPrice,
  ChartProps,
  BalanceProps,
  Followers,
  TraderData,
} from "./interface";

export class getApiMethod {
  private api: AxiosInstance;
  private apiMarket: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.API_DB,
    });
    this.apiMarket = axios.create({
      baseURL: process.env.API_MAKET,
    });
  }

  async getTokens(): Promise<Token[]> {
    try {
      const res = await this.api.get<Token[]>(`/tokens`);
      const dataBack = res.data as Token[];
      return dataBack
        .filter((x) => x.mint !== undefined)
        .sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
    } catch (error) {
      console.error(error);
    }
  }

  // async getTokensById(id: string): Promise<Token> {
  //   const res = await this.api.get<Token>(`/tokens/${id}/`);
  //   return res.data as Token;
  // }

  async getTokensById(id: string): Promise<Token> {
    try {
      const res = await this.api.get<Token>(`/tokens/${id}/`);
      const tokenData = res.data as Token;

      const object: Token = {
        ...tokenData,
      };
      return object;
    } catch (error) {
      console.error(error);
    }
  }

  async getTokenByWallet(id: string): Promise<Token[]> {
    try {
      const res = await this.api.get<Token[]>(
        `/tokens/filter-by-wallet-creator/?wallet_creator=${id}`
      );
      const dataBack = res.data as Token[];

      return dataBack
        .filter((x) => x.mint !== undefined)
        .sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
    } catch (error) {
      console.error(error);
    }
  }

  async createToken(tokenData: TokenBody): Promise<AxiosResponse<TokenBody>> {
    return await this.api.post<TokenBody>(`/tokens/`, tokenData);
  }

  async getTransactions(mint: string): Promise<Transaction[]> {
    const res = await this.api.get<Transaction[]>(
      `/transactions/filter-by-mint/?mint=${mint}`
    );
    return res.data as Transaction[];
  }

  async createTransaction(
    transactionData: TransactionBody
  ): Promise<AxiosResponse<TransactionBody>> {
    return await this.api.post<TransactionBody>(
      `/transactions/`,
      transactionData
    );
  }

  async updateTransaction(
    id: string,
    transactionData: Partial<TransactionBody>
  ): Promise<AxiosResponse<TransactionBody>> {
    return await this.api.patch<TransactionBody>(
      `/transactions/${id}/`,
      transactionData
    );
  }

  async getUser(id: string): Promise<User> {
    const user = (await this.api.get<User>(`/users/${id}/`)).data as User;

    if (user.referred_by) {
      const userRef = (
        await this.api.get<User>(
          `/users/filter-by-referred-by/?referred_by=${user.referred_by}/`
        )
      ).data as User;
      return { ...user, userRef } as User;
    }
    return user;
  }

  async createUser(ipfsData: UserBody): Promise<AxiosResponse<UserBody>> {
    return await this.api.post<UserBody>(`/users/`, ipfsData);
  }

  async updateUser(
    id: string,
    ipfsData: Partial<UserBody>
  ): Promise<AxiosResponse<UserBody>> {
    return await this.api.patch<UserBody>(`/users/${id}/`, ipfsData);
  }

  async getFollowersByPublick(
    id: string
  ): Promise<{ count: number; results: Followers[] }> {
    return (
      await this.api.get<{ count: number; results: Followers[] }>(
        `/followers/filter-by-pubkey/?pubkey=${id}`
      )
    ).data as { count: number; results: Followers[] };
  }

  async getFollowersByFollowing(
    id: string
  ): Promise<{ count: number; results: Followers[] }> {
    return (
      await this.api.get<{ count: number; results: Followers[] }>(
        `/followers/filter-by-followerkey/?followerkey=${id}`
      )
    ).data as { count: number; results: Followers[] };
  }

  async createFollowers(
    ipfsData: FollowersBody
  ): Promise<AxiosResponse<FollowersBody>> {
    return await this.api.post<FollowersBody>(`/followers/`, ipfsData);
  }

  async updateFollowers(
    id: string,
    ipfsData: Partial<FollowersBody>
  ): Promise<AxiosResponse<FollowersBody>> {
    return await this.api.patch<FollowersBody>(`/followers/${id}/`, ipfsData);
  }

  async getCommentsBymint(mint: string): Promise<Comments[]> {
    const res = await this.api.get<Comments[]>(
      `/comments/filter-by-mint/?mint=${mint}`
    );
    return res.data as Comments[];
  }

  async createCommnet(
    ipfsData: CommentsBody
  ): Promise<AxiosResponse<CommentsBody>> {
    return await this.api.post<CommentsBody>(`/comments/`, ipfsData);
  }

  async createImageCommnet(
    ipfsData: imagescommentBody
  ): Promise<AxiosResponse<CommentsBody>> {
    return await this.api.post<CommentsBody>(`/imagescomment/`, ipfsData);
  }

  async getMarkertInfoById(id: string): Promise<MaketCap> {
    try {
      const res = await this.apiMarket.get<MaketCap>(`/token/${id}`);
      return res.data as MaketCap;
    } catch (error) {
      console.log(error);
    }
  }

  async getTokenInfo(): Promise<MaketCap[]> {
    try {
      const res = await this.apiMarket.get<MaketCap[]>(`/token`);
      return res.data as MaketCap[];
    } catch (error) {
      console.log(error);
    }
  }

  async getMarkertPricebyId(id: string): Promise<MaketCapPrice> {
    const res = await this.apiMarket.get<MaketCapPrice>(`/token/${id}/price`);
    return res.data as MaketCapPrice;
  }

  async getChartMarketById(
    id: string,
    start: number,
    end: number
  ): Promise<ChartProps[]> {
    try {
      const res = await this.apiMarket.get<ChartProps[]>(
        `/market/${id}?sample_by=300`
      );
      return res.data.reverse() as ChartProps[];
    } catch (error) {
      console.error(error);
    }
  }

  async getTokenBalances(id: string): Promise<BalanceProps[]> {
    try {
      const res = await this.apiMarket.get<
        { token: string; balance: number }[]
      >(`/balance/${id}`);
      const data = res.data as { token: string; balance: number }[];

      const balances = await Promise.all(
        data.map(async (item) => {
          const token = await this.getTokensById(item.token);
          return {
            id: null,
            created_at: null,
            quantity: item.balance.toString(),
            pubkey: id,
            token: token,
            updated_at: null,
          };
        })
      );

      return balances as BalanceProps[];
    } catch (error) {
      console.error(error);
    }
  }

  async getTokenBalanceByMint(id: string, mint: string): Promise<BalanceProps> {
    try {
      const res = await this.apiMarket.get<
        { token: string; balance: number }[]
      >(`/balance/${id}`);
      const data = res.data as { token: string; balance: number }[];

      const balances = await Promise.all(
        data.map(async (item) => {
          const token = await this.getTokensById(item.token);
          return {
            id: null,
            created_at: null,
            quantity: item.balance.toString(),
            pubkey: id,
            token: token,
            updated_at: null,
          };
        })
      );

      const balance = balances.find((balance) => balance.token.mint === mint);
      return balance ? balance : null;
    } catch (error) {
      console.error(error);
    }
  }

  async getTraderData(id: string): Promise<TraderData[]> {
    try {
      const res = await this.apiMarket.get<TraderData[]>(`/trades/${id}`);
      return res.data as TraderData[];
    } catch (error) {
      console.error(error);
    }
  }

  async getTokenByname(id: string): Promise<Token[]> {
    const res = await this.api.get<Token[]>(
      `/tokens/filter-by-name/?value=${id}`
    );
    return res.data;
  }

  async getTokenOrdernByName(): Promise<Token[]> {
    try {
      const res = await this.api.get<Token[]>(`/tokens/order-by-name/`);
      const dataBack = res.data as Token[];

      return dataBack;
    } catch (error) {
      console.error(error);
    }
  }

  async getTokenOrdernByLaunched(): Promise<Token[]> {
    try {
      const res = await this.api.get<Token[]>(`/tokens`);
      const dataBack = res.data as Token[];
      return dataBack
        .filter((x) => x.launched === true)
        .sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
    } catch (error) {
      console.error(error);
    }
  }

  // async getTokenOrdernByMarkecap(): Promise<Token[]> {
  //   try {
  //     const response = await this.apiMarket.get<MaketCap[]>(`/token`);
  //     const dataToken = response.data as MaketCap[];

  //     const res = await this.api.get<Token[]>(`/tokens`);
  //     const dataBack = res.data as Token[];
  //     return dataToken
  //       .map((data) => {
  //         const tokenData = dataBack.find((x) => x.mint === data.mint);
  //         const obje: Token = {
  //           ...tokenData,
  //           marketcap: data.market_cap,
  //         };
  //         return obje;
  //       })
  //       .filter((x) => x.mint !== undefined)
  //       .sort((a, b) => {
  //         return parseFloat(b.marketcap) - parseFloat(a.marketcap);
  //       });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  async getTokenOrdernByMarkecap(): Promise<Token[]> {
    try {
      const tokenResponse = await this.api.get<Token[]>(`/tokens`);
      const tokenData = tokenResponse.data;

      // // Current date and time
      // const now = new Date();

      // // Separate tokens based on launch date
      // const recentLaunchTokens: Token[] = [];
      // const otherTokens: Token[] = [];

      // marketCapData.forEach((marketCapItem) => {
      //   const tokenItem = tokenData.find(
      //     (token) => token.mint === marketCapItem.mint
      //   );
      //   if (tokenItem) {
      //     const launchDate = tokenItem.launch_date
      //       ? new Date(tokenItem.launch_date)
      //       : null;

      //     // Check if launch date is within 15 minutes from now
      //     const isRecentLaunch = launchDate
      //       ? (now.getTime() - launchDate.getTime()) / (1000 * 60) <= 15
      //       : false;

      //     if (isRecentLaunch) {
      //       recentLaunchTokens.push({
      //         ...tokenItem,
      //         marketcap: marketCapItem.market_cap,
      //       });
      //     } else {
      //       otherTokens.push({
      //         ...tokenItem,
      //         marketcap: marketCapItem.market_cap,
      //       });
      //     }
      //   }
      // });

      // // Sort other tokens by market cap in descending order
      // const sortedOtherTokens = otherTokens.sort(
      //   (a, b) =>
      //     parseFloat(b.marketcap || "0") - parseFloat(a.marketcap || "0")
      // );

      // // Concatenate recent launch tokens with other sorted tokens
      // const sortedTokens = [...recentLaunchTokens, ...sortedOtherTokens];

      return tokenData;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getTokenLastLauncher(): Promise<Token> {
    const res = await this.api.get<Token[]>(`/tokens/launched-tokens/`);
    if (res.data[0]) {
      const dataToken = [...res.data][0];
      return await this.getTokensById(dataToken.mint);
    }
  }
}
